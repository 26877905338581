export default {
  selectors: {
    btnOverAge: '.batcom-agegate__buttons .batcom-agegate__button-over-age .cmp-button',
    btnUnderAge: '.batcom-agegate__buttons .batcom-agegate__button-under-age .cmp-button',
    btnOverAgeText: '.batcom-agegate__buttons .batcom-agegate__button-over-age .cmp-button__text',
    btnUnderAgeText: '.batcom-agegate__buttons .batcom-agegate__button-under-age .cmp-button__text',
    ageGateText: '.batcom-agegate__text',
    ageGateButtons: '.batcom-agegate__buttons',
    ageGateFootNote: '.batcom-agegate__footnote',
    ageGateProvincesContainer: '.batcom-agegate__provinces',
    languageNav: '.batcom-agegate__languagenavigation',
    btnLangSwitch: '.batcom-agegate__languagenavigation-button',
    underAgeText: '.batcom-agegate__text-under',
    selectProvinceInput: '.batcom-agegate__provinces-select',
    contentData: '.batcom-agegate__content',
    chatIcon: '.genesys-app',
  },
  classes: {
    hidden: 'batcom-hide',
    noScroll: 'noScroll',
  },
  regex: {
    language: /\/([a-z]{2})(?:\/[a-z]{2})/i,
  },
};
