import { register } from '@netcentric/component-loader';
import { addBodyScroll, removeBodyScroll, setCookie, getCookie, waitForElement } from 'commons/utils';
import { AGE_GATE_COOKIE_SET } from 'commons/constants/events';
import cfg from './batcom-agegate.config';

class Agegate {
  constructor(el, params) {
    this.el = el;
    this.cookie = params.cookie;
    this.consentAge = params.age;
    this.setRefs();
    this.init();
  }

  init() {
    // return if user is a bot
    const isBot = /bot|googlebot|crawler|spider|robot|crawling|slurp|baiduspider|yandexbot|bingbot|semrushbot|ahrefsbot|duckduckbot|facebot|twitterbot|rogerbot|majesticbot|pingdom|google web preview|google search appliance|google-site-verification|Google-InspectionTool/i.test(navigator.userAgent);

    if (isBot) {
      setCookie('agegate', 'true');
      setCookie('regionLocation', `Ontario`);
      setCookie('regionCode', `ON`);
      setCookie('softVerifyRegion', `ON`);
      setCookie(this.cookie.key, this.cookie.value);
      return;
    }
    if (!window.Granite && !this.isAgeGateCookieSet()) { // if not author and cookie 'ageGateValidation' is not set
      addBodyScroll(cfg.classes.noScroll);
      this.setButtonConsentAgeEl(this.consentAge);
      this.updateLangLinks();

      if (this.selectProvinceInput) {
        this.setSelectedProvince();
      }

      this.contentData.classList.remove(cfg.classes.hidden);

      this.setEventListeners();
      waitForElement(cfg.selectors.chatIcon, (element) => {
        element?.classList.add(cfg.classes.hidden);
      });
    }

    this.redirectUserToProvince();
  }

  setRefs() {
    this.contentData = this.el.querySelector(cfg.selectors.contentData);
    this.ageGateText = this.el.querySelector(cfg.selectors.ageGateText);
    this.underAgeText = this.el.querySelector(cfg.selectors.underAgeText);
    this.underAgeButton = this.el.querySelector(cfg.selectors.btnUnderAge);
    this.selectProvinceInput = this.el.querySelector(cfg.selectors.selectProvinceInput);
    this.langLinks = this.el.querySelectorAll(cfg.selectors.btnLangSwitch);
    this.btnOverAgeText = this.el.querySelector(cfg.selectors.btnOverAgeText);
    this.btnUnderAgeText = this.el.querySelector(cfg.selectors.btnUnderAgeText);
    this.ageGateFootNote = this.el.querySelector(cfg.selectors.ageGateFootNote);
    this.ageGateTextInner = this.ageGateText.innerHTML;
    this.btnOverAgeTextInner = this.btnOverAgeText.innerHTML;
    this.btnUnderAgeTextInner = this.btnUnderAgeText.innerHTML;
    this.ageGateFootNoteInner = this.ageGateFootNote.innerHTML;
    this.chatIcon = document.querySelector(cfg.selectors.chatIcon);
  }

  isAgeGateCookieSet() {
    return document.cookie.includes(this.cookie.key);
  }

  setEventListeners() {
    this.el.querySelector(cfg.selectors.btnOverAge).addEventListener('click', () => {
      setCookie(this.cookie.key, this.cookie.value, this.cookie.expirationDate, this.cookie.domain, this.cookie.path);
      if (this.selectProvinceInput) {
        setCookie('regionCode', this.provinceAbbreviation, this.cookie.expirationDate, this.cookie.domain, this.cookie.path);
        setCookie('regionLocation', this.provinceValue, this.cookie.expirationDate, this.cookie.domain, this.cookie.path);
        setCookie('softVerifyRegion', this.provinceAbbreviation, this.cookie.expirationDate, this.cookie.domain, this.cookie.path);
        window.PubSub.publish(AGE_GATE_COOKIE_SET, 'regionCode');

        // in case province chances, remove current cart
        localStorage.removeItem('checkoutId');
      }

      if (this.provinceLink){
        setCookie('regionRedirection', this.provinceLink, this.cookie.expirationDate, this.cookie.domain, this.cookie.path);
      }

      this.hide();
      this.redirectUserToProvince();
    });

    if (this.underAgeText) {
      this.underAgeButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.redirectUser(this.underAgeButton);
      });
    }

    this.selectProvinceInput?.addEventListener('change', () => {
      this.setSelectedProvince();
    });
  }

  setSelectedProvince() {
    const selectedOption = this.selectProvinceInput.selectedOptions[0];

    this.provinceValue = selectedOption.value;
    this.provinceAbbreviation = selectedOption.dataset.abbreviation;
    this.consentAge = selectedOption.dataset.consentAge;
    this.provinceLink = selectedOption.dataset.href;

    this.setButtonConsentAgeEl(this.consentAge);
  }

  updateLangLinks() {
    if (this.langLinks.length) {
      let currentPath = window.location.pathname;
      currentPath = currentPath.replace('language-masters', 'ca'); // exception for testing purposes on language-masters paths
      const match = currentPath.match(cfg.regex.language);
      const countryCode = match[1];
      this.langLinks.forEach(link => {
        const langCode = link.dataset.language;
        link.href = `${currentPath.replace(cfg.regex.language, `/${countryCode}/${langCode}`)}`;
      });
    }
  }

  setButtonConsentAgeEl(age) {
    if (this.ageGateText) {
      this.ageGateText.innerHTML = this.ageGateTextInner.replace('{{age}}', age);
    }
    if (this.btnOverAgeText) {
      this.btnOverAgeText.innerHTML = this.btnOverAgeTextInner.replace('{{age}}', age);
      if (this.btnOverAgeText.parentElement.title) {
        this.btnOverAgeText.parentElement.title = this.btnOverAgeTextInner.replace('{{age}}', age);
      }
    }
    if (this.btnUnderAgeText) {
      this.btnUnderAgeText.innerHTML = this.btnUnderAgeTextInner.replace('{{age}}', age);
      if (this.btnUnderAgeText.parentElement.title) {
        this.btnUnderAgeText.parentElement.title = this.btnUnderAgeTextInner.replace('{{age}}', age);
      }
    }
    if (this.ageGateFootNote) {
      this.ageGateFootNote.innerHTML = this.ageGateFootNoteInner.replace('{{age}}', age);
    }
  }

  redirectUser(button) {
    this.el.querySelector(cfg.selectors.ageGateText).classList.add(cfg.classes.hidden);
    this.el.querySelector(cfg.selectors.ageGateProvincesContainer)?.classList.add(cfg.classes.hidden);
    this.el.querySelector(cfg.selectors.ageGateButtons).classList.add(cfg.classes.hidden);
    this.el.querySelector(cfg.selectors.languageNav)?.classList.add(cfg.classes.hidden);
    this.ageGateFootNote.classList.add(cfg.classes.hidden);
    this.underAgeText.classList.remove(cfg.classes.hidden);
    setTimeout(() => { window.location = button.href }, 5000);
  }


  redirectUserToProvince() {
    const regionRedirection = getCookie('regionRedirection');
    if (regionRedirection && !window.Granite && !window.location.href.includes(regionRedirection)) {
        history.replaceState({}, "", regionRedirection);
        history.go(0);
    }
  }

  hide() {
    this.el.classList.add(cfg.classes.hidden);
    removeBodyScroll(cfg.classes.noScroll);
    this.chatIcon?.classList.remove(cfg.classes.hidden);
  }
}

register({ Agegate });
